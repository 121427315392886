import React, { useEffect } from "react";

const EmbedResource = ({ htmlCode }) => {
  useEffect(() => {
    // Function to extract and inject the <script> tag
    const extractScript = (htmlString) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlString, "text/html");
      const scriptTag = doc.querySelector("script");
      if (scriptTag) {
        const scriptElement = document.createElement("script");
        scriptElement.async = true;
        scriptElement.defer = true;
        scriptElement.innerHTML = scriptTag.innerHTML;
        document.body.appendChild(scriptElement); // Append script to the body (same DOM)
      }
    };

    // Inject script from the htmlCode
    extractScript(htmlCode);

    // Cleanup function to remove the injected script on unmount
    return () => {
      const insertedScript = document.querySelector(
        `script[async][defer][src="//dyv6f9ner1ir9.cloudfront.net/assets/js/nloader.js"]`
      );
      if (insertedScript) document.body.removeChild(insertedScript);
    };
  }, [htmlCode]);

  return (
    <div>
      {/* Inject only the div part */}
      <div
        dangerouslySetInnerHTML={{
          __html: htmlCode?.split("<script")[0],
        }}
      />
    </div>
  );
};

export default EmbedResource;
